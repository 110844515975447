<template>
  <div class="bg">
    <div class="container">
      <div class="img-box">
        <img class="imgStyle" src="../assets/img/login/logo_black.png" alt/>
      </div>
      <div class="sub-title">质量管理协同工作平台</div>
      <div class="item">
        <div class="item-checked">
          <div>手机登录</div>
          <div class="checked-line"></div>
        </div>
        <div>
          <div>电子邮箱登录</div>
          <div class="checked-line"></div>
        </div>
      </div>
      <div class="input-box">
        <input placeholder="请输入手机号">
      </div>
      <div class="input-box">
        <input type="email" placeholder="请输入电子邮箱">
      </div>
      <div class="input-box">
        <input type="password" placeholder="请输入密码">
      </div>
      <div class="agreement">
        <input type="checkbox">
        <div>请阅读并同意</div>
        <div class="agreement-btn">子牛隐私协议</div>
      </div>
      <div class="login-btn">登录</div>
      <div class="action">
        <div>忘记密码？</div>
        <div>没有账号？</div>
      </div>
    </div>
    <div class="copyright">Copyright © 2019 欧凯嵘创检测科技</div>
  </div>
</template>
<script>
export default {
  data() {
    return {}
  },
  activated() {
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.bg {
  height: 100vh;
  background-image: url("../assets/img/login/bg.jpg");
  background-size: 100% 100%;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  .container {

    .img-box {
      width: 240px;
      height: 81px;
      margin: 0 auto 8px;
    }

    .sub-title {
      color: var(--title-color);
      text-align: center;
      margin-bottom: 24px;
    }

    .item {
      margin: auto;
      width: 336px;
      display: flex;
      align-items: center;
      justify-content: space-around;

      div {
        width: 128px;
        height: 56px;
        text-align: center;
        line-height: 56px;
        cursor: pointer;
      }

      .item-checked {
        color: var(--theme-color1);
        font-weight: bold;
      }

      .checked-line {
        width: 40px;
        height: 2px;
        background-color: var(--theme-color1);
        margin: auto;
      }
    }

    .input-box {
      margin-top: 24px;

      input {
        width: 440px;
        height: 56px;
        border: 1px solid #ffffff;
        border-radius: 8px;
        padding: 0 16px;
        font-size: 16px;
        color: var(--title-color);
      }

      input::placeholder {
        font-size: 16px;
        color: #999999;
      }

      input:focus {
        border-color: var(--theme-color1);
        outline-color: var(--theme-color1);
      }
    }

    .agreement {
      margin-top: 24px;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      input[type="checkbox"] {
        width: 16px;
        height: 16px;
        margin: 3px 8px 0 0;
        border: 1px solid var(--theme-color1);
      }

      input[type="checkbox"]:checked {
        background-color: var(--theme-color1);
      }

      .agreement-btn {
        color: var(--theme-color1);
        cursor: pointer;
        margin-left: 4px;
      }
    }

    .login-btn {
      width: 440px;
      height: 56px;
      background-color: var(--theme-color1);
      color: #ffffff;
      text-align: center;
      line-height: 56px;
      border-radius: 8px;
      margin-top: 40px;
    }

    .login-btn:hover {
      background-color: var(--theme-color2);
    }

    .action {
      width: 440px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-top: 16px;
      color: var(--theme-color1);
    }
  }

  .copyright {
    color: #999999;
    font-size: 12px;
    margin-bottom: 24px;
  }
}
</style>
